<template>
  <div class="text-right">
    <div style="margin-bottom: 0.46rem;padding-top: 0.245rem;">
      <div class="titlebox">
        <div class="dian" style="margin-bottom: 0"></div>
        <div class="titleb">{{$t('bindAccount.Payment_Method')}}</div>
      </div>
      <div class="paymentMethod" @click="selectPopup(0)">
        <div class="payoneerNamebox">
          <img :src="methodlist[radio].icon" alt="">
          <div class="payoneerName">{{radio==2?$t('bindAccount.Bank_Transfer'):methodlist[radio].name}}</div>
        </div>
        <img src="../assets/gengduo.png" alt="">
      </div>
    </div>

    <div v-if="radio==0">
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.paypal_email')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field v-model="Paypal.email" :placeholder="$t('bindAccount.placeholder.paypal_email')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.paypal_email')}}</div>
      </div>
    </div>

    <div v-if="radio==1">
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.payoneerID')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field type="number" v-model="Payoneer.payoneerID" :placeholder="$t('bindAccount.placeholder.payoneerID')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.payoneerID')}}</div>
      </div>
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.payoneer_email')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field v-model="Payoneer.email" :placeholder="$t('bindAccount.placeholder.payoneer_email')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.payoneer_email')}}</div>
      </div>
    </div>

    <div v-if="radio==2">
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.country')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field type="text" v-model="BankTransfer.country" :placeholder="$t('bindAccount.placeholder.country')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.country_code')}}</div>
      </div>
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.bankCode')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field type="text" v-model="BankTransfer.bankCode" :placeholder="$t('bindAccount.placeholder.bankCode')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.bankCode')}}</div>
      </div>
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.bankName')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field type="text" v-model="BankTransfer.bankName" :placeholder="$t('bindAccount.placeholder.bankName')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.bankName')}}</div>
      </div>
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.bank_card_number')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field type="number" v-model="BankTransfer.bank_card_number" :placeholder="$t('bindAccount.placeholder.bank_card_number')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.bank_card_number')}}</div>
      </div>
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.bank_account_name')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field type="text" v-model="BankTransfer.bank_account_name" :placeholder="$t('bindAccount.placeholder.bank_account_name')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.account_name_tips')}}</div>
      </div>
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.Payee_Last_Name')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field type="text" v-model="BankTransfer.Payee_Last_Name" :placeholder="$t('bindAccount.placeholder.Payee_Last_Name')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.name_tips')}}</div>
      </div>
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.Payee_First_name')}}</div>
        </div>
        <div class="paymentMethod">
          <van-field type="text" v-model="BankTransfer.Payee_First_name" :placeholder="$t('bindAccount.placeholder.Payee_First_name')" />
        </div>
        <div class="promptinfo">{{$t('bindAccount.limit.name_tips')}}</div>
      </div>
      <div style="margin-bottom: 0.46rem;">
        <div class="titlebox">
          <div class="dian" style="margin-bottom: 0"></div>
          <div class="titleb">{{$t('bindAccount.title.Payee_Address')}}</div>
        </div>
        <div style="padding-bottom: 0.33rem;">
          <div class="paymentMethod">
            <van-field type="text" v-model="BankTransfer.Payee_Address.Beneficiary_country_code" :placeholder="$t('bindAccount.placeholder.Beneficiary_country_code')" />
          </div>
          <div class="promptinfo">{{$t('bindAccount.limit.country_code')}}</div>
        </div>
        <div style="padding-bottom: 0.33rem;">
          <div class="paymentMethod">
            <van-field type="text" v-model="BankTransfer.Payee_Address.State_or_Province" :placeholder="$t('bindAccount.placeholder.State_or_Province')" />
          </div>
          <div class="promptinfo">{{$t('bindAccount.limit.State_or_Province')}}</div>
        </div>
        <div style="padding-bottom: 0.33rem;">
          <div class="paymentMethod">
            <van-field type="text" v-model="BankTransfer.Payee_Address.City" :placeholder="$t('bindAccount.placeholder.City')" />
          </div>
          <div class="promptinfo">{{$t('bindAccount.limit.City')}}</div>
        </div>
        <div style="padding-bottom: 0.33rem;">
          <div class="paymentMethod">
            <van-field type="text" v-model="BankTransfer.Payee_Address.Street" :placeholder="$t('bindAccount.placeholder.Street')" />
          </div>
          <div class="promptinfo">{{$t('bindAccount.limit.Street')}}</div>
        </div>
        <div style="padding-bottom: 0.33rem;">
          <div class="paymentMethod">
            <van-field type="number" v-model="BankTransfer.Payee_Address.StrePostal_Codeet" :placeholder="$t('bindAccount.placeholder.Postal_Code')" />
          </div>
          <div class="promptinfo">{{$t('bindAccount.limit.StrePostal_Codeet')}}</div>
        </div>
      </div>

    </div>

    <div class="complete" :class="{  'nav-hide': navhide }" @click="selectPopup(1)">{{$t('bindAccount.popup.complete')}}
    </div>

    <van-popup v-model="selectShow" round position="bottom">
      <span v-if="indexs==0">
        <div class="methodtoast">{{$t('bindAccount.popup.payment_method')}}</div>
        <div style="margin: 0 0.34rem 0.5rem ;">
          <div class="methodtoastbox" v-for="(item, index) in methodlist" :key="index" :style="index==0?'border-top:0':''" @click="radio = index">
            <div style="display: flex;align-items: center;flex: 1;">
              <img style="width:0.31rem;height: 0.31rem;" :src="item.icon" alt="">
              <span class="selectName" :style="index==radio?'':'color: #B0B6B6'">{{index==2?$t('bindAccount.Bank_Transfer'):item.name}}</span>
            </div>
            <van-radio-group v-model="radio" @change="selectChange(index)">
              <van-radio :name="index">
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? inactiveIcon : activeIcon" />
                </template>
              </van-radio>
            </van-radio-group>
          </div>
        </div>
      </span>
      <span v-if="indexs!=0">
        <div style="margin: 0 0.34rem;">
          <div class="boundAccount">
            {{$t('bindAccount.popup.Bound_account')}}{{radio==0?Paypal.email:radio==1?Payoneer.payoneerID:BankTransfer.bank_card_number}}
          </div>
          <div class="boundAccountinfo">{{$t('bindAccount.toast.payoneer_account')}}</div>
          <div class="caozuo">
            <div class="cancel" @click="cancel">{{$t('bindAccount.popup.cancel')}}</div>
            <div class="confirm" @click="confirms">{{$t('bindAccount.popup.confirm')}}</div>
          </div>
        </div>
      </span>

    </van-popup>
  </div>
</template>

<script>
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
import Cookies from 'js-cookie';
import selectfalse from '../assets/selectf.png'
import selecttrue from '../assets/selectt.png'
import Paypal from '../assets/social-paypal.png'
import Payoneer from '../assets/payoneer.png'
import BankTransfer from '../assets/bankTransfer.png'

export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      selectShow: false,
      radio: 0,
      activeIcon: selectfalse,
      inactiveIcon: selecttrue,
      methodlist: [
        { name: 'Paypal', icon: Paypal },
        { name: 'Payoneer', icon: Payoneer },
        { name: 'Bank Transfer', icon: BankTransfer },
      ],
      indexs: 0,
      defaultPhoneHeight: null,//屏幕默认高度
      nowPhoneHeights: null, //屏幕现在的高度
      navhide: false,//手机软键盘是否被唤起
      Paypal: {
        email: '',
      },
      Payoneer: {
        payoneerID: '',
        email: '',
      },
      BankTransfer: {
        country: '',
        bankCode: '',
        bankName: '',
        bank_card_number: '',
        bank_account_name: '',
        Payee_Last_Name: '',
        Payee_First_name: '',
        Payee_Address: {
          Beneficiary_country_code: '',
          State_or_Province: '',
          City: '',
          Street: '',
          StrePostal_Codeet: '',
        },
      },
      token: null,
      uid: null,
      headers: null,
      userInfo: [],

    };
  },
  created() {
    // this.$completes.loadErudaJs()
    // this.findWithdrawInfo()

  },
  mounted() {
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
    document.getElementsByTagName("html")[0].style.backgroundColor = "#ffffff";
    this.defaultPhoneHeight = window.innerHeight
    window.onresize = () => {
      this.nowPhoneHeights = window.innerHeight
    }
    
  },

  destroyed() {
    window.onresize = null
  },
  watch: {
    nowPhoneHeights: 'nowPhoneHeight',
    //银行卡 提现输入限制
    'BankTransfer.country': function () {
      this.BankTransfer.country = this.BankTransfer.country.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.bankCode': function () {
      this.BankTransfer.bankCode = this.BankTransfer.bankCode.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.bankName': function () {
      this.BankTransfer.bankName = this.BankTransfer.bankName.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.bank_account_name': function () {
      this.BankTransfer.bank_account_name = this.BankTransfer.bank_account_name.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.Payee_Last_Name': function () {
      this.BankTransfer.Payee_Last_Name = this.BankTransfer.Payee_Last_Name.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.Payee_First_name': function () {
      this.BankTransfer.Payee_First_name = this.BankTransfer.Payee_First_name.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.Payee_Address.Beneficiary_country_code': function () {
      this.BankTransfer.Payee_Address.Beneficiary_country_code = this.BankTransfer.Payee_Address.Beneficiary_country_code.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.Payee_Address.State_or_Province': function () {
      this.BankTransfer.Payee_Address.State_or_Province = this.BankTransfer.Payee_Address.State_or_Province.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.Payee_Address.State_or_Province': function () {
      this.BankTransfer.Payee_Address.State_or_Province = this.BankTransfer.Payee_Address.State_or_Province.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.Payee_Address.City': function () {
      this.BankTransfer.Payee_Address.City = this.BankTransfer.Payee_Address.City.replace(/[^A-Za-z]/g, '');
    },
    'BankTransfer.Payee_Address.Street': function () {
      this.BankTransfer.Payee_Address.Street = this.BankTransfer.Payee_Address.Street.replace(/[^A-Za-z]/g, '');
    },

  },

  methods: {
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('bindAccount.Bind_Account')
      this.findWithdrawInfo()


    },

    //软键盘检测
    nowPhoneHeight: function () {
      if (this.defaultPhoneHeight != this.nowPhoneHeights) {
        //手机键盘被唤起了。
        this.navhide = true

      } else {

        if (this.radio == 2) {
          this.navhide = true
        }
        else {
          this.navhide = false
        }
        //手机键盘被关闭了。
      }
    },

    //邮箱验证
    emailyz(s) {
      var regu =
        "^(([0-9a-zA-Z]+)|([0-9a-zA-Z]+[_.0-9a-zA-Z-]*[0-9a-zA-Z]+))@([a-zA-Z0-9-]+[.])+([a-zA-Z]{2}|net|NET|com|COM|gov|GOV|mil|MIL|org|ORG|edu|EDU|int|INT)$"
      var re = new RegExp(regu);
      if (s.search(re) != -1) {
        this.selectShow = true
        return true;
      } else {
        Toast(this.$t('bindAccount.toast.emailyz'))
        return false;
      }
    },


    //提示信息
    selectPopup(index) {
      this.indexs = index
      if (index == 0) {
        this.selectShow = true
      }
      else {
        if (this.radio == 0) {
          if (this.Paypal.email == '') {
            Toast(this.$t('bindAccount.toast.paypal_email'))
          }
          else {
            this.emailyz(this.Paypal.email)
          }
        }
        else if (this.radio == 1) {
          if (this.Payoneer.payoneerID == '') {
            Toast(this.$t('bindAccount.toast.payoneerID'))

          }
          else if (this.Payoneer.email == '') {
            Toast(this.$t('bindAccount.toast.payoneer_email'))
          }
          else {
            this.emailyz(this.Payoneer.email)
          }
        }
        else if (this.radio == 2) {
          if (this.BankTransfer.country == '') {
            Toast(this.$t('bindAccount.toast.country'))
          }
          else if (this.BankTransfer.bankCode == '') {
            Toast(this.$t('bindAccount.toast.bankCode'))
          }
          else if (this.BankTransfer.bankName == '') {
            Toast(this.$t('bindAccount.toast.bankName'))
          }
          else if (this.BankTransfer.bank_card_number == '') {
            Toast(this.$t('bindAccount.toast.bank_card_number'))
          }
          else if (this.BankTransfer.bank_account_name == '') {
            Toast(this.$t('bindAccount.toast.bank_account_name'))
          }
          else if (this.BankTransfer.Payee_Last_Name == '') {
            Toast(this.$t('bindAccount.toast.Payee_Last_Name'))
          }
          else if (this.BankTransfer.Payee_First_name == '') {
            Toast(this.$t('bindAccount.toast.Payee_First_name'))
          }
          else if (this.BankTransfer.Payee_Address.Beneficiary_country_code == '') {
            Toast(this.$t('bindAccount.toast.Beneficiary_country_code'))
          }
          else if (this.BankTransfer.Payee_Address.State_or_Province == '') {
            Toast(this.$t('bindAccount.toast.State_or_Province'))
          }
          else if (this.BankTransfer.Payee_Address.City == '') {
            Toast(this.$t('bindAccount.toast.City'))
          }
          else if (this.BankTransfer.Payee_Address.Street == '') {
            Toast(this.$t('bindAccount.toast.Street'))
          }
          else {
            this.selectShow = true
          }
        }

      }
    },
    selectChange(index) {
      this.selectShow = false
      if (this.radio == 2) {
        this.navhide = true
      }
      else {
        this.navhide = false
      }
    },

    cancel() {
      this.selectShow = false
    },
    //提交提现资料或更改
    confirms() {
      let bindJson = ''
      let type = ''
      if (this.radio == 0) {
        type = 2
        bindJson = JSON.stringify({
          paypalAccountEmail: this.Paypal.email
        })
      }
      else if (this.radio == 1) {
        type = 1
        bindJson = JSON.stringify({
          payoneerID: this.Payoneer.payoneerID,
          payoneerAccountEmail: this.Payoneer.email
        })
      }
      else if (this.radio == 2) {
        type = 3
        bindJson = JSON.stringify({
          countryOfBankCard: this.BankTransfer.country,
          beneficiaryBankCode: this.BankTransfer.bankCode,
          beneficiaryBankName: this.BankTransfer.bankName,
          payeeBankCardNumber: this.BankTransfer.bank_card_number,
          payeeBankAccountName: this.BankTransfer.bank_account_name,
          payeeLastName: this.BankTransfer.Payee_Last_Name,
          payeeFirstname: this.BankTransfer.Payee_First_name,
          countryCode: this.BankTransfer.Payee_Address.Beneficiary_country_code,
          stateOrProvince: this.BankTransfer.Payee_Address.State_or_Province,
          city: this.BankTransfer.Payee_Address.City,
          street: this.BankTransfer.Payee_Address.Street,
          postal: this.BankTransfer.Payee_Address.StrePostal_Codeet,
        })
      }
      let paramObjs = {
        option: 'post',
        host: this.$serviceIp + 'api/trade/withdraw/addWithdrawBind?token=' + this.token + '&uid=' + this.uid,
        data: {
          type: type,
          bindJson: bindJson
        },
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          Toast(this.$t('bindAccount.toast.filledSuccessfully'))
          this.selectShow = false
          this.$completes.complete("getPreviousPage");
        }
      })


    },

    //获取用户提现资料
    findWithdrawInfo() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/trade/withdraw/findWithdrawInfo?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          if (data.data.bindJson != undefined) {
            let datas = JSON.parse(data.data.bindJson)
            if (data.data.type == 2) {
              that.radio = 0
              that.Paypal.email = datas.paypalAccountEmail
            }
            else if (data.data.type == 1) {
              that.radio = 1
              that.Payoneer.payoneerID = datas.payoneerID
              that.Payoneer.email = datas.payoneerAccountEmail
            }
            else if (data.data.type == 3) {
              that.radio = 2
              that.navhide = true
              that.BankTransfer.country = datas.countryOfBankCard
              that.BankTransfer.bankCode = datas.beneficiaryBankCode
              that.BankTransfer.bankName = datas.beneficiaryBankName
              that.BankTransfer.bank_card_number = datas.payeeBankCardNumber
              that.BankTransfer.bank_account_name = datas.payeeBankAccountName
              that.BankTransfer.Payee_Last_Name = datas.payeeLastName
              that.BankTransfer.Payee_First_name = datas.payeeFirstname
              that.BankTransfer.Payee_Address.Beneficiary_country_code = datas.countryCode
              that.BankTransfer.Payee_Address.State_or_Province = datas.stateOrProvince
              that.BankTransfer.Payee_Address.City = datas.city
              that.BankTransfer.Payee_Address.Street = datas.street
              that.BankTransfer.Payee_Address.StrePostal_Codeet = datas.postal
            }
          }

        }
      })
    },
  },
};

</script>

<style>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */
html[lang="ar"] .text-right {
  direction: rtl;
}

html[lang="ar"] .van-field__control {
  text-align: right;
}

html[lang="ar"] .titlebox {
  display: flex;
  margin-bottom: 0.19rem;
  direction: rtl;
}

html[lang="ar"] .paymentMethod {
  direction: rtl;
}

html[lang="ar"] .paymentMethod img:nth-child(2) {
  -webkit-transform: rotateY(180deg);
}

html[lang="ar"] .dian {
  margin: 0.12rem 0 0.6rem 0.07rem;
}

html[lang="ar"] .selectName {
  padding-right: 0.27rem;
}

html[lang="ar"] .cancel {
  margin-left: 0.06rem;
}

html[lang="ar"] .confirm {
  margin-right: 0.06rem;
}

.dian {
  width: 0.07rem;
  height: 0.07rem;
  background: #5dc48a;
  border-radius: 50%;
  min-width: 0.07rem;
  min-height: 0.07rem;
  margin: 0.12rem 0.07rem 0.6rem 0;
}

.titlebox {
  display: flex;
  margin-bottom: 0.19rem;
}

.titleb {
  font-size: 0.27rem;
  font-family: mediums;
  font-weight: 400;
  color: #000000;
  line-height: 0.31rem;
  /* padding: 0.245rem 0 0 0; */
}

.paymentMethod {
  background: #f6f7fb;
  border-radius: 0.13rem;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  height: 0.76rem;
}

.payoneerNamebox {
  display: flex;
  align-items: center;
  flex: 1;
}

.payoneerName {
  font-size: 0.22rem;
  font-family: Regular;
  font-weight: 400;
  color: #000000;
  line-height: 1;
}

.paymentMethod img:nth-child(1) {
  width: 0.288rem;
  height: 0.288rem;
  margin: 0 0.17rem 0 0.2rem;
}

.paymentMethod img:nth-child(2) {
  width: 0.4rem;
  height: 0.4rem;
}

.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background: none;
}
.van-field__control {
  color: #000000;
  font-family: Regular;
  font-size: 0.22rem;
}
.van-field__control::-webkit-input-placeholder {
  color: #b0b6b6;
  font-family: Regular;
  font-size: 0.22rem;
}

.promptinfo {
  width: 100%;
  font-size: 0.17rem;
  font-family: Regular;
  color: #b0b6b6;
  padding-top: 0.16rem;
}

.boundAccountinfo {
  width: 100%;
  font-size: 0.2rem;
  font-family: Regular;
  color: #b0b6b6;
  padding-top: 0.16rem;
}

.complete {
  color: #ffffff;
  font-size: 0.27rem;
  background: #5dc48a;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  padding: 0.28rem 0 0.26rem;
  font-family: mediums;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 16px 0 16px;
  bottom: 0.83rem;
  line-height: var(--footer-height);
}

.methodtoast {
  color: #282828;
  font-size: 0.29rem;
  font-family: Semibold;
  text-align: center;
  padding: 0.47rem 0 0.2rem;
  line-height: 1;
}

.boundAccount {
  color: #282828;
  font-size: 0.29rem;
  font-family: Semibold;
  padding: 0.4rem 0 0;
}

.methodtoastbox {
  display: flex;
  align-items: center;
  padding: 0.35rem 0;
  border-top: 1px solid #f1f1f2;
}

.selectName {
  font-size: 0.25rem;
  color: #282828;
  padding-left: 0.27rem;
}

.paymentmethod_select {
}

.img-icon {
  width: 0.25rem;
  height: 0.25rem;
}

.nav-hide {
  position: static !important;
  margin: 0.83rem 0;
}

.cancel {
  color: #666666;
  background: #f8f8f8;
  font-size: 0.27rem;
  border-radius: 50px;
  margin-right: 0.06rem;
  width: 2.77rem;
  height: 0.87rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: mediums;
}

.confirm {
  color: #ffffff;
  background: #5dc48a;
  font-size: 0.27rem;
  border-radius: 50px;
  margin-left: 0.06rem;
  width: 2.77rem;
  height: 0.87rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: mediums;
}

.caozuo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.41rem 0 1rem;
}

.van-popup--bottom.van-popup--round {
  border-radius: 25px 25px 0 0;
}
</style>